import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import "../Styles/Layout.css";

function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleItemClick = (e, index) => {
    setToggleMenu(false);
    //e.currentTarget.classList.toggle('change');
    switch (index) {
      case 0:
        navigate("/sprzedaz");
        break;
      case 1:
        navigate("/wynajem");
        break;
      case 2:
        navigate("/projekty");
        break;
      case 3:
        navigate("/kontakt");
        break;
      default:
    }
  };

  const toggleMenuButtonClick = (e) => {
    setToggleMenu(!toggleMenu);
    //e.currentTarget.classList.toggle('change');
  };

  return (
    <>
      <header className="Navbar">
        <div className="links">
          <img
            src={logo}
            alt="logo"
            className="pointer"
            height={65}
            onClick={() => navigate("/")}
          />
          <ul className={`${toggleMenu ? "active" : ""}`}>
            <li
              className={`${location.pathname === '/sprzedaz' ? "active" : ""}`}
              onClick={(e) => handleItemClick(e, 0)}
            >
              <Link to="/">SPRZEDAŻ NIERUCHOMOŚCI</Link>
            </li>
            <li
              className={`${location.pathname === '/wynajem' ? "active" : ""}`}
              onClick={(e) => handleItemClick(e, 1)}
            >
              <Link to="/">OBSŁUGA WYNAJMU</Link>
            </li>
            <li
              className={`${location.pathname === '/projekty' ? "active" : ""}`}
              onClick={(e) => handleItemClick(e, 2)}
            >
              <Link to="/">OBSŁUGA PROJEKTÓW</Link>
            </li>
            <li
              className={`${location.pathname === '/kontakt' ? "active" : ""}`}
              onClick={(e) => handleItemClick(e, 3)}
            >
              <Link to="/">KONTAKT</Link>
            </li>
          </ul>
          <div className={'burger ' + (toggleMenu ? 'change' : '')} onClick={toggleMenuButtonClick}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
        {toggleMenu && <div className="overlay"></div>}
      </header>
      <Outlet />
      <footer id='footer'>
        <p>Wszelkie prawa zastrzeżone © 2023 UNIKUM</p>
      </footer>
    </>
  );
}

export default Layout;
