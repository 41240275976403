import React from 'react';
import '../Styles/ContactPage.css'
import contact from '../Assets/contact.jpg'
import phone_icon from '../Assets/phone.png'
import mail_icon from '../Assets/email.png'

function ContactPage() {
    return (
        <div className="ContactPage">
            <div className='content'>
                <section>
                    <div className='w-40'>
                    <img src={contact} alt='budowa'></img>
                    </div>
                    <div className='w-60'>
                        <div>
                            <p className='header'>Skontaktuj się z nami!</p>
                            <div className='icon-text'>
                                <img src={phone_icon} alt='Phone icon' className='icon'></img>
                                <p className='text' id='phone'><span>+48 501 295 371</span></p>
                            </div>
                            <div className='icon-text'>
                                <img src={mail_icon} alt='Mail icon' className='icon'></img>
                                <p className='text' id='mail'><span>oferty@unikumnieruchomosci.pl</span></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ContactPage;