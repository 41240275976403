import { Route, Routes } from 'react-router-dom';
import './Styles/App.css';
import HomePage from './Components/HomePage';
import Error404Page from './Components/Error404Page';
import SalePage from './Components/SalePage';
import RentalPage from './Components/RentalPage';
import ProjectPage from './Components/ProjectPage';
import ContactPage from './Components/ContactPage';
import Layout from './Components/Layout';

function App() {
  return (
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<HomePage />} />
          <Route path="/sprzedaz" element={<SalePage />} />
          <Route path="/wynajem" element={<RentalPage />} />
          <Route path="/projekty" element={<ProjectPage />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="*" element={<Error404Page />} />
        </Route>
      </Routes>
  );
}

export default App;
