import "../Styles/SalePage.css";
import { M3n, Platforma, Ang, Oaza } from "../Assets";
import klucze from "../Assets/klucze.jpg";

function SalePage() {
  return (
    <div className="SalePage">
      <div className="background-sale"></div>
      <div className="content">
        <section>
          <div className="w-40">
            <img
              src={klucze}
              alt="klucze"
              title="Obraz autorstwa gpointstudio na Freepik"
            ></img>
          </div>
          <div className="w-60 lp">
            <div>
              <p className="text">
                <span className="bold">Sprzedaj</span> z nami swoją nieruchomość
                lub <span className="bold">wybierz mieszkanie</span> spośród
                szerokiej oferty mieszkań!
              </p>
              <p className="text">
                Mamy kilkunastoletnie doświadczenie w sprzedaży mieszkań i
                lokali usługowych na rynku pierwotnym.
              </p>
              <p className="text">
                W naszej ofercie znajdują się zarówno lokale od{" "}
                <span className="bold">
                  najlepszych trójmiejskich deweloperów, jak i atrakcyjne okazje
                  z rynku wtórnego.
                </span>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="w-100">
            <div>
              <p className="text">
                <span className="bold">Oferujemy pomoc</span> w sprzedaży
                mieszkań oraz nieruchomości gruntowych.
              </p>
              <p className="text">
                Pozwól, aby nasz zespół pomógł Ci wycenić Twoją nieruchomość i
                uzyskać możliwie najwyższą cenę.
              </p>
              <p className="text">
                <span className="bold">Pomożemy Ci</span> w uzyskaniu kredytu na
                zakup wymarzonej nieruchomości.
              </p>
              <p className="text">
                <span className="bold">Współpracujemy</span> ze sprawdzonymi
                deweloperami, inżynierami, rzeczoznawcami oraz firmami
                zajmującymi się wykończeniem pod klucz.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="w-100">
            <a href="https://www.otodom.pl/pl/firmy/biura-nieruchomosci/unikum-nieruchomosci-ID9461654" target="_blank" rel="noreferrer">
              <button>NASZE OFERTY</button>
            </a>
          </div>
        </section>
        <section>
          <div className="w-100">
            <h1>
              <span className="bold">Współpracujemy z:</span>
            </h1>
            <div id="loga">
              <a href="https://m3nieruchomosci.pl/" target='_blank' rel="noreferrer">
                <img src={M3n} alt="logo" />
              </a>
              <a href="https://grupaang.pl/" target='_blank' rel="noreferrer">
                <img src={Ang} alt="logo" />
              </a>
              <a href="https://www.platformamieszkaniowa.pl/" target='_blank' rel="noreferrer">
                <img src={Platforma} alt="logo" />
              </a>
              <a href="https://baltyckaoaza.com.pl/" target='_blank' rel="noreferrer">
                <img src={Oaza} alt="logo" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SalePage;
