import React from 'react';
import '../Styles/ProjectPage.css'
import budowa from '../Assets/budowa.jpg';
import budynek from '../Assets/budynek.jpg';

function ProjectPage() {
    return (
        <div className="ProjectPage">
            <div className='background-project'/>
            <div className='content'>
                <section>
                 <div className='w-40'>
                 <img src={budowa} alt='budowa' title='Obraz autorstwa zinkevych na Freepik'></img>
                 </div>
                 <div className='w-60 lp'>
                    <div>
                        <p className='text'><span className='bold'>Chciałbyś zostać deweloperem?</span></p>
                        <p className='text'>Szukasz firmy, która pomoże Ci zrealizować swój pierwszy projekt deweloperski?</p>
                        <p className='text'><span className='bold'>Dysponujesz gruntem</span> pod zabudowę mieszkaniową lub wielorodzinną ale nie masz wiedzy i potrzebnych kwalifikacji, aby <span className='bold'>wybudować budynek na sprzedaż lub wynajem?</span></p>
                        <p className='text'>Dzięki niemalże 18-letniemu doświadczeniu w branży nieruchomości, głównie w zakresie projektów deweloperskich, jesteśmy w stanie <span className='bold'>poprowadzić realizację inwestycji początku do końca.</span></p>
                        <p className='text'>Pomożemy przy wyborze działki, projekcie, budowie, obsłudze sprzedażowej aż po przekazanie lokali nabywcom lub komercjalizację obiektów handlowych.</p>
                    </div>
                 </div>
                </section>
                <section>
                 <div className='w-60'>
                    <div>
                        <p className='text'><span className='bold'>Nasze doświadczenie</span></p>
                        <p className='text'>Zespół UNIKUM współpracował przy budowie, sprzedaży i odbiorach ponad <span className='bold'>1200 mieszkań w 18 inwestycjach</span> na terenie Trójmiasta i okolic.</p>
                    </div>
                 </div>
                 <div className='w-40'>
                 <img src={budynek} alt='budynek'></img>
                 </div>
                </section>
            </div>
        </div>
    );
}

export default ProjectPage;