import React from 'react';
import '../Styles/HomePage.css'
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const navigate = useNavigate();
    return (
        <div className="HomePage">
            <div id='home-content'>
                <div className='image-container sale' onClick={() => navigate('/sprzedaz')}>
                    <span className='tile-title'>SPRZEDAŻ NIERUCHOMOŚCI</span>
                </div>
                <div className='image-container rental' onClick={() => navigate('/wynajem')}>
                    <span className='tile-title'>OBSŁUGA<br/> WYNAJMU APARTAMENTÓW</span>
                </div>
                <div className='image-container project' onClick={() => navigate('/projekty')}>
                    <span className='tile-title'>OBSŁUGA PROJEKTÓW DEWELOPERSKICH</span>
                </div>
            </div>
        </div>
    );
}

export default HomePage;