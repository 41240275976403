import React from 'react';
import '../Styles/RentalPage.css'
import dochod from '../Assets/dochod.jpg'
import woman from '../Assets/kobieta.jpg'

function RentalPage() {
    return (
        <div className="RentalPage">
            <div className='background-rental'></div>
            <div className='content'>
                <section>
                 <div className='w-40'>
                    <img src={woman} alt='kobieta' title='Obraz autorstwa Freepik'></img>
                 </div>
                 <div className='w-60 lp'>
                    <div>
                        <p className='text'>Jesteś właścicielem mieszkania w atrakcyjnej, turystycznej lokalizacji w Trójmieście?</p>
                        <p className='text'>Chciałbyś osiągać <span className='bold'>duży przychód z tytułu wynajmu</span>, ale nie masz czasu aby się tym zająć?</p>
                        <p className='text'>Pozwól nam zrobić to za Ciebie!</p>
                        <p className='text'>Oferujemy kompleksową obsługę apartamentów przeznaczonych na <span className='bold'>wynajem krótko- i długoterminowy.</span></p>
                        <p className='text'>Zespół <span className='bold'>UNIKUM</span> przygotuje twoje mieszkanie, wykona profesjonalną sesję zdjęciową, przygotuje ofertę do serwisów typu booking.com oraz airbnb, zaproponuje wzór umowy, zajmie się obsługą Klienta.</p>
                    </div>
                 </div>
                </section>
                <section>
                 <div className='w-60'>
                    <div>
                        <p className='header'>Jaki dochód możesz uzyskać?</p>
                        <p className='text'>Przy wynajmie krótkoterminowym można zarobić nawet <span className='bold'>kilkukrotnie więcej</span> niż za najem długoterminowy!</p> 
                    </div>
                 </div>
                 <div className='w-40'>
                    <img src={dochod} alt='dolary'></img>
                 </div>
                </section>
                <section>
                 <div className='w-100'>
                    <p className='header margin-bottom'>Zobacz nasz przykładowy apartament!</p>
                    <a href="https://www.booking.com/hotel/pl/apartament-mila-gdynia.pl.html" target='_blank' rel="noreferrer">
                        <button>APARTAMENT MILA GDYNIA</button>
                    </a>
                 </div>
                </section>
            </div>
        </div>
    );
}

export default RentalPage;