import React from 'react';
import '../Styles/Error404Page.css'
import { Link } from 'react-router-dom';

function Error404Page() {
    return (
        <div className="Error404Page">
            <div>
                <p id="sad">:(</p>
                <div>
                    <h1>BŁĄD 404</h1>
                    <h2>Przepraszamy, strona o podanym adresie nie istnieje.<br/>
                    <Link to="/">Wróć do strony głównej</Link>
                    </h2>
                </div>
            </div>
        </div>
    );
}

export default Error404Page;